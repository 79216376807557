import Head from 'next/head'
import Logo from "@/components/general/Logo";
import Footer from "@/components/general/Footer";


export default function Home({box}) {
  return (
    <div>
      <Head>
        <title>FlitsPils</title>
        <meta name="description" content={"404"} />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="p-5 container mx-auto min-h-screen justify-centerc">
        <div>
          <Logo />
        </div>
        <div className={"mt-10"}>
          404
        </div>
      </div>
      <Footer />
    </div>
  )
}
